import React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import { motion, useAnimation } from "framer-motion";
import { InView, useInView } from "react-intersection-observer";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="alt-hero" id="about">
          <div className="container">
            <div className="why">
              <InView threshold={0.2}>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, transform: "translate(-50px)" }}
                    animate={
                      inView
                        ? { opacity: 1, transform: "translate(0px)" }
                        : { opacity: 1 }
                    }
                    transition={{
                      type: "spring",
                      stiffness: 100,
                      duration: 3,
                      delay: 0.25,
                    }}
                  ><h3
                  data-scroll
                  data-scroll-direction="horizontal"
                  data-scroll-speed="4"
                  data-scroll-position="top"
                  data-scroll-delay="0.2"
                >
                    Live Jobs</h3>
                  </motion.div>
                )}
              </InView>
            </div>
            <div className="title">
              <InView threshold={0.2}>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, transform: "translate(-50px)" }}
                    animate={
                      inView
                        ? { opacity: 1, transform: "translate(0px)" }
                        : { opacity: 1 }
                    }
                    transition={{
                      type: "spring",
                      stiffness: 100,
                      duration: 3,
                      delay: 0.25,
                    }}
                  ><h2
                  data-scroll
                  data-scroll-direction="horizontal"
                  data-scroll-speed="2"
                  data-scroll-position="top"
                  data-scroll-delay="0.2"
                >
                    We know the importance of job satisfaction. We have the connections, knowledge and expertise to get you where you want to be.</h2>
                  </motion.div>
                )}
              </InView>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
