import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { motion, useAnimation } from "framer-motion";
import { InView, useInView } from "react-intersection-observer";

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="live-jobs-new-grid">
        {posts &&
          posts.map(({ node: post }) => (
            <InView threshold={0.2}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0 }}
                animate={
                  inView 
                    ? { opacity: 1}
                    : { opacity: 1 }
                }
                transition={{
                  type: "spring",
                  stiffness: 100,
                  duration: 3,
                  delay: 0.25,
                }}
                className="live-jobs-new-item" 
                key={post.id}
              >                <div className="live-jobs-new-button">
              <Link to={post.fields.slug}>View Role</Link>
            </div>
                <div className="live-jobs-new-info">
                  <h3 className="job-info-block-bigger">{post.frontmatter.jobtitle}</h3>
                  <p className="job-info-block"><span>Salary</span>{post.frontmatter.salary}</p>
                  <p className="job-info-block"><span>Client</span>{post.frontmatter.company}</p>
                  <p className="job-info-block"><span>Location</span>{post.frontmatter.location}</p>
                </div>

              </motion.div>
              )}
            </InView>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                jobtitle
                templateKey
                date(formatString: "MMMM, YYYY")
                company
                location
                type
                duration
                salary
                description
                requirements
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
